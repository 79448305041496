import {  reactive } from "vue";
import axios from '../plugins/axios';
import { useRoute } from 'vue-router';
import router from '../router'
import { store } from "../store";

export default function Auth() {
    const route = useRoute();

    let user = reactive({
        email: '',
        password: '',
        loading: false,
        error: {
            message: '',
            class: 'error',
            email: {
                message: '',
                class: 'error',
            },
            password: {
                message: '',
                class: 'error',
            }
        }
    });
    const loggedIn = () => {
        user.loading = true;
        user.error = {
            message: '',
            class: '',
            email: {
                message: '',
                class: '',
            },
            password: {
                message: '',
                class: '',
            }
        }

         axios.api.post("/login", {
            email: user.email,
            password: user.password
        }).then(async(response) => {
            if (response.data.status) {
                localStorage.setItem('LoggedUser', response.data.data.token);
                localStorage.setItem('IsloggIn', true);
                localStorage.setItem('username', response.data.data.user.name);
                localStorage.setItem('email', response.data.data.user.email);
                localStorage.setItem('company_id', response.data.data.user.company_id || '');
                localStorage.setItem('user_hash', response.data.data.user.user_hash);
                localStorage.setItem('phone_number', response.data.data.user.company.phone_number || '');
                localStorage.setItem('company_name', response.data.data.user.company.name || '');
                localStorage.setItem('last_name', response.data.data.user.last_name || '');
                localStorage.setItem('compnay_tg_id', response.data.data.user.company.trade_gecko_id || '');
                localStorage.setItem('user_id', response.data.data.user.id || '');
                localStorage.setItem('last_login', response.data.data.user.last_login_at || '');
                
                if(response.data.data.distributor == 1){
                    localStorage.setItem('distributor', true);
                }

                window.location.href = '/home';
            } else {
                if (response.data.error.error) {
                    user.error.message = response.data.error.error;
                    user.error.class = 'danger';
                }
                if (response.data.error.email) {
                    user.error.email.message = response.data.error.email[0];
                    user.error.email.class = 'danger';
                }
                if (response.data.error.password) {
                    user.error.password.message = response.data.error.password[0];
                    user.error.password.class = 'danger';
                }
            }
        }).catch(error => {
            console.log(error)
        }).finally(() => (user.loading = false))
    };

    let userEmail = reactive({
        email: '',
        loading: false,
        msg: {
            message: ''
        },
        error: {
            message: '',
            class: 'error',
            email: {
                message: '',
                class: 'error',
            }
        }
    });

    const forgetPassword = async() => {
        userEmail.loading = true;
        userEmail.msg = {
                message: ''
            },
            userEmail.error = {
                message: '',
                class: '',
                email: {
                    message: '',
                    class: '',
                }
            }

        axios.api.post("/forgot-password", {
            email: userEmail.email,
        }).then((response) => {
            if (response.data.status) {
                userEmail.msg.message = response.data.msg
            } else {
                if (response.data.error) {
                    userEmail.error.message = response.data.error;
                    userEmail.error.class = 'danger';
                }
                if (response.data.error.email) {
                    userEmail.error.email.message = response.data.error.email[0];
                    userEmail.error.email.class = 'danger';
                }
            }
        }).catch(error => {
            console.log(error)
        }).finally(() => (userEmail.loading = false))
    };

    let resetpassword = reactive({
        password: '',
        password_confirmation: '',
        loading: false,
        error: {
            message: '',
            class: 'error',
            password: {
                message: '',
                class: 'error',
            },
            password_confirmation: {
                message: '',
                class: 'error',
            }
        }
    });
    const Passwordreset = async() => {
        resetpassword.loading = true;
        resetpassword.error = {
            message: '',
            class: '',
            password: {
                message: '',
                class: '',
            },
            password_confirmation: {
                message: '',
                class: '',
            }
        }

        axios.api.post("/reset-password", {
            password: resetpassword.password,
            password_confirmation: resetpassword.password_confirmation,
            email: route.path.split('/')[2],
            token: route.path.split('/')[3],
        }).then((response) => {
            if (response.data.status) {
                router.push("/login");
            } else {
                if (response.data.error) {
                    store.dispatch('setToast', { status: true, msg: response.data.error, type: 'danger' })
                }
                if (response.data.error) {
                    resetpassword.error.message = response.data.error;
                    resetpassword.error.class = 'danger';
                }
                if (response.data.error.password) {
                    resetpassword.error.password.message = response.data.error.password[0];
                    resetpassword.error.password.class = 'danger';
                }
                if (response.data.error.password_confirmation) {
                    resetpassword.error.password_confirmation.message = response.data.error.password_confirmation[0];
                    resetpassword.error.password_confirmation.class = 'danger';
                }
            }
        }).catch(error => {
            console.log(error)
        }).finally(() => (resetpassword.loading = false))


    };

    const loggedOut = async() => {

        axios.authApi.post("/logout")
            .then( _ => {
                console.log('logout')
        }).catch(err => {
            console.log(err);
        });

        localStorage.removeItem("LoggedUser");
        localStorage.removeItem("username");
        localStorage.removeItem("email");
        localStorage.removeItem("company_id");
        localStorage.removeItem("user_hash");
        localStorage.removeItem("phone_number");
        localStorage.removeItem("company_name");
        localStorage.removeItem("last_name");
        localStorage.removeItem('compnay_tg_id');
        localStorage.removeItem('user_id');
        localStorage.removeItem('distributor');
    
        window.location.href = '/home';
    }

    function isLoggedIn() {
        return !!localStorage.getItem('LoggedUser');
    }

    function isDistributor() {
        return !!localStorage.getItem('distributor');
    }

    return {
        user,
        userEmail,
        resetpassword,
        forgetPassword,
        Passwordreset,
        loggedIn,
        loggedOut,
        isLoggedIn,
        isDistributor
    };

}