import { inject } from "vue";
import Segments from "../modules/Segments";
import Auth from "../modules/Auth";
const { ProductAdded, ProductRemoved,ClearCart } = Segments();
const { loggedOut } = Auth();
export const cart = {
    state: () => ({
        cart: [],
        loading: false,
        items_count: 0
    }),

    getters: {
        cart: (state) => state.cart,
        items_count: (state) => state.items_count,
        loading: (state) => state.loading
    },

    mutations: {
        SET_CART(state, cart) {
            state.cart = cart
        },
        SET_ITEMS_COUNT(state, count) {
            state.items_count = count
        },
        SET_BULK_QTY(state, data) {
            let cat = data.cat[0];
            let check = true;
            let cart = state.cart;
            if(cat){
                state.cart.itemsByCat = cart.itemsByCat.map(function(item) {
                    if (item.id === cat.id) {
                      check = false;
                      return cat;
                    } else {
                      return item;
                    }
                });

                // state.cart.itemsByCat.forEach((item, i, arr) => {
                //     if(item.id == cat.id){
                //         state.cart.itemsByCat[i] = cat;
                //         check = false;
                //         arr.length = i + 1
                //     }
                // });
                
                if (check) state.cart.itemsByCat.push(cat);
            } else {
                state.cart.itemsByCat = state.cart.itemsByCat.filter(item => item.name != data.category);
            }
            state.cart.bulkTags = data.bulk_tags;
        },
        LOADING(state, loading){
            state.loading = loading
        }
    },

    actions: {
        getCart({ commit },{ax = null}) {
            const axios = ax ? ax : inject('axios');
            if(axios.isLoggedIn){
                commit('LOADING', true)
                axios.authApi.get('/cart').then((resp) => {
                    if (resp.status) {
                        commit('SET_CART', resp.data.data)
                        commit('SET_ITEMS_COUNT', resp.data.data.totalQuantity);
                        commit('LOADING', false)
                    } else {
                        console.log(resp.data.error);
                    }
                }).catch(function (error) {
                    if(error.response.status == 401){
                        loggedOut();
                    }
                    console.log(error.response);
                });
            }
        },

        UpdateCart({ commit, getters }, {variant_id, qty,segmentQty, axios, route,add, catRank, brandRank,position,sort, list_id, list_type}) {
              
            if (!isNaN(qty) && qty > 0) {
                axios.authApi
                .post("/add-to-cart", {
                    variant_id: variant_id,
                    qty: qty,
                    position,
                    sort,
                    list_id,
                    list_type
                })
                .then((resp) => {
                    if (resp.status) {
                        commit('SET_BULK_QTY', resp.data.data);
                        commit('SET_ITEMS_COUNT', resp.data.data.totalQty);
                        // commit('SET_CART', resp.data.data);
                        
                            if(add && segmentQty > 0){
                                ProductAdded(segmentQty,resp.data.data,catRank,brandRank);
                            }else{
                                ProductRemoved(Math.abs(segmentQty),resp.data.data,catRank,brandRank)
                            } 
                        } else {
                            console.log(resp.data.error);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        UpdateBlukIntoCart({ commit, dispatch }, items) {
                //  axios.authApi.post("/addProductsToCart",{items: items})
                // .then((resp) => {
                //     if (resp.data.status) {
                        commit('SET_CART', items);
                        commit('SET_ITEMS_COUNT', items.totalQuantity);
                        dispatch("setToast",{status:true,msg:"Your Order Add Into Cart"})
                //     } else {
                //         dispatch("setToast",{status:true,msg:"Your Order Add Into Cartasdasd", type:})
                //         // console.log(resp.data);
                //     }
                // })
                // .catch(function(error) {
                //     console.log(error);
                // }).finally(() => (0));
        },

        removeItemFromCart({ commit,getters }, {variant_id, qty, axios, route}) {
            let item = null;
            getters.cart.itemsByCat.forEach(el => {
                el.items.forEach(r => {
                    if(r.variantId == variant_id){
                        item = {...r, 'category': el.name,}
                    }
                });
            });
            axios.authApi
                .post("/cart", {
                    variant_id: variant_id,
                    qty: -Math.abs(qty),
                })
                .then((resp) => {
                    if (resp.status) {
                        let item = null;
                        let data = resp.data.data;
                        data.deleted_variant_id = variant_id;
                        getters.cart.itemsByCat.forEach(el => {
                            el.items.forEach(r => {
                                if(r.variantId == variant_id){
                                    item = {...r, 'category': el.name,}
                                }
                            });
                        });
                        // ProductRemoved(variant_id,getters.cart, route);
                        ProductRemoved(item.qty,variant_id,item, route);
                        commit('SET_CART', resp.data.data);
                        commit('SET_ITEMS_COUNT', resp.data.data.totalQuantity);
                    } else {
                        console.log(resp.data.error);
                        return resp.data.error
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        removeFromCart({ commit,getters }, {variant_id, qty,segmentQty, axios, route}) {

            axios.authApi
                .post("/remove-from-cart", {
                    variant_id: variant_id,
                })
                .then((resp) => {
                    if (resp.status) {
                        let data = resp.data.data;
                        data.deleted_variant_id = variant_id;
                        data.deleted_variant_qty = 0;
                        commit('SET_BULK_QTY', resp.data.data);
                        commit('SET_ITEMS_COUNT', resp.data.data.totalQty);
                        ProductRemoved(segmentQty,resp.data.data);
                    } else {
                        console.log(resp.data.error);
                        return resp.data.error
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        clearCart({ commit, getters },{axios, route}) {
          
            axios.authApi
                .get("/clear-cart")
                .then((resp) => {
                    if (resp.data.status) {
                        let items = [];
                        getters.cart.itemsByCat.forEach(el => {
                            el.items.forEach(arr => {
                                items.push({
                                    list_id : arr.brandName,
                                    list_type: route.path.split('/')[1],
                                    position: arr.position,
                                    brand: arr.brandName,
                                    category: el.name,
                                    product_id: arr.productId,
                                    product_sku: arr.slug,
                                    name: arr.productName,
                                    variant_id: arr.variantId,
                                    variant: arr.optionLabel,
                                    price: arr.singlePrice,
                                    quantity: arr.qty,
                                    value: arr.singlePrice * arr.qty,
                                    platform: 'website',
                                });
                            });
                        });
                        ClearCart(items)
                        commit('SET_ITEMS_COUNT', 0);
                        commit('SET_CART', resp.data.data);
                    } else {
                        console.log(resp.data.error);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }
}